import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { Divider, FormControl, Tooltip, FormControlLabel, Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  getDataDictionaryValues,
  getMasterClass,
  getOpportunity,
  getPartners,
} from "../../utils/api/api";
import {
  BASE_URL,
  DD_TYPE_BANK_TYPE,
  DD_TYPE_DP_NAMES,
  DD_TYPE_INVESTOR_TYPE,
  SNACKBAR_ERROR,
  DEMAT_CHARGES,
} from "../../utils/constants/constants";
import DrawerLayout from "../../components/layout/DrawerLayout";
import "react-phone-input-2/lib/material.css";
import { formatDateTime, toLocalePriceNoDecimal } from "../../utils/StringUtil";
import { Info } from "@mui/icons-material";
import DialogLayout from "../../components/layout/DialogLayout";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const InvestorInfo = ({ open, onClose, investor, isEdit }) => {
  const [generatedBy, setGeneratedBy] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [opportunityList, setOpportunityList] = useState([]);
  const [invRid, setInvRid] = useState(null);
  const [userRid, setUserRid] = useState(null);
  const [isMobileAuthenticated, setIsMobileAuthenticated] = useState(false);
  const [investorFullName, setInvestorFullName] = useState("");
  const [investorEmail, setInvestorEmail] = useState("");
  const [intlPhoneNumber, setIntlPhoneNumber] = useState("");
  const [intlCountryCode, setIntlCountryCode] = useState("");
  const [investorCode, setInvestorCode] = useState("");
  const [invUpFront, setInvUpFront] = useState(null);
  const [partnerRid, setPartnerRid] = useState(null);
  const [partnerList, setPartnerList] = useState([]);
  const [investorPan, setInvestorPan] = useState("");
  const [ckycStatus, setCkycStatus] = useState(0);
  const [mcaStatus, setMcaStatus] = useState(0);
  const [ckycStatusFromServer, setCkycStatusFromServer] = useState(0);
  const [mcaStatusFromServer, setMcaStatusFromServer] = useState(0);
  const [imRid, setImRid] = useState(0);
  const [invPreference, setInvPreference] = useState(0);
  const [sendMcaEmail, setSendMcaEmail] = useState(false);
  const [resendMcaEmail, setResendMcaEmail] = useState(false);
  const [masterClassList, setMasterClassList] = useState([]);
  const [cmRid, setCmRid] = useState(null);
  const [carry, setCarry] = useState(0.0);
  const [hurdle, setHurdle] = useState(0.0);
  const [address, setAddress] = useState("");
  const [bankName, setBankName] = useState(null);
  const [bankNameIndex, setBankNameIndex] = useState(null);
  const [bankNameList, setBankNameList] = useState([]);
  const [accountNo, setAccountNo] = useState("");
  const [ifsc, setIFSC] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [investorType, setInvestorType] = useState(null);
  const [investorTypeList, setInvestorTypeList] = useState([]);
  const [stampPaper, setStampPaper] = useState(0);
  const [welcomeEmail, setWelcomeEmail] = useState(false);
  const [dob, setDOB] = useState();
  const userType = process.env.REACT_APP_USER_TYPE;

  const [kycDetails, setKycDetails] = useState(null);
  const [showKycDetails, setShowKycDetails] = useState(null);
  const [kycImageData, setKycImageData] = useState(null);

  const [dpNameList, setDpNameList] = useState([]);
  const [newDetails, setNewDetails] = useState({});
  const [dematCharges, setDematCharges] = useState(0.00);
  const [includeDematCharges, setIncludeDematCharges] = useState(false);
  const handleIncludeDematCharges = () => {
    setDematCharges(includeDematCharges ? 0.00 : DEMAT_CHARGES);
    setIncludeDematCharges(!includeDematCharges);
  }

  const toggleKycImage = (imageData) => {
    if (kycImageData) {
      setKycImageData(null);
    } else {
      setKycImageData(imageData);
    }
  }

  const getPartnersList = () => {
    setPartnerList([]);
    getPartners()
      .then((res) => {
        setPartnerList(res.partnerMasterList);
      })
      .catch((e) => {
        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  };

  const getOpportunityList = () => {
    getOpportunity()
      .then((data) => {
        setOpportunityList(data.opportunityList ? data.opportunityList : []);
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const getMasterClassList = (param = {}) => {
    param.status = 1;
    getMasterClass(param)
      .then((data) => {
        setMasterClassList(data.classMasters);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (open) {
      setShowKycDetails(false);
      setKycImageData(null);
      setGeneratedBy(null);
      setIntlPhoneNumber(
        (investor?.countryCode ? investor?.countryCode : "") +
        (investor?.mobile ? investor?.mobile : "")
      );
      setIntlCountryCode(investor?.countryCode ? investor?.countryCode : "");
      setUserRid(investor?.userRid);
      setInvUpFront(investor?.upfront);
      setCmRid(investor?.cmRid || null);
      setCarry(carry);
      setHurdle(hurdle);
      setPartnerRid(investor?.partnerRid ?? null);
      setAddress(investor?.address ?? "");
      setBankName(null);
      setBankNameIndex(investor?.bankIndex ?? null);
      setAccountNo(investor.accountNo ?? "");
      setIFSC(investor.ifscSwift ?? "");
      setBankAddress(investor?.bankAddress ?? "");
      setInvestorType(investor?.investorType ?? null);
      setStampPaper(investor?.stampPaper ?? 0);
      setIncludeDematCharges(investor?.includeDematCharge ?? 0);
      setDOB(investor?.dobDoi ?? "");
      setWelcomeEmail(false);
      setKycDetails(investor.ckycDataObject);

      setInvRid(investor?.invRid);
      setInvestorFullName(investor?.fullName ? investor.fullName : "");
      setInvestorCode(investor?.invCode ? investor.invCode : "");
      setInvestorEmail(investor?.email ? investor.email : "");
      setInvestorPan(investor?.pan ? investor.pan : "");
      setCkycStatus(investor?.ckycStatus);
      setMcaStatus(investor?.mcaStatus);
      setCkycStatusFromServer(investor?.ckycStatus);
      setMcaStatusFromServer(investor?.mcaStatus);
      setInvPreference(investor?.invPref);
      setPartnerRid(investor?.partnerRid);
      setSendMcaEmail(investor.mcaToken ? true : false);
      setResendMcaEmail(false);


      setNewDetails({
        gstNo: investor?.gstNo,
        dpDdIndex: investor?.dpDdIndex,
        dpName: investor?.dpName,
        dematAccNo: investor?.dematAccNo,
        dpId: investor?.dpId,
        dpClientId: investor?.dpClientId,
        firstHolderName: investor?.firstHolderName,
        cmlCopyObject: investor?.cmlCopyObject,
        nsdlCdsl: investor?.nsdlCdsl
      });

      getDataDictionary();
      getPartnersList();
    }
  }, [open]);

  const getDataDictionary = () => {
    getDataDictionaryValues()
      .then((data) => {
        const invTypes = data.filter(
          (item) => item.ddType === DD_TYPE_INVESTOR_TYPE
        );
        const bankTypes = data.filter(
          (item) => item.ddType === DD_TYPE_BANK_TYPE
        );
        const dpNames = data.filter((item) =>
          item.ddType === DD_TYPE_DP_NAMES
        )
        setInvestorTypeList(invTypes);
        setBankNameList(bankTypes);
        setDpNameList(dpNames);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const masterClass =
      masterClassList.find((option) => option.cmRid === cmRid) || null;
    if (masterClass) {
      setCarry(masterClass.carry);
      setHurdle(masterClass.hurdleRate);
    }
  }, [cmRid]);

  useEffect(() => {
    getOpportunityList();
    getMasterClassList();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DrawerLayout
        drawerWidth={"50%"}
        open={open}
        title={investor?.fullName}
        closeAction={onClose}
        positiveAction={{ label: "Close", onClick: onClose }}
      >
        <DialogLayout
          title={''}
          open={kycImageData !== null}
          maxWidth={'xs'}
          closeAction={() => toggleKycImage()}
        >
          <div className="d-flex justify-content-center">
            <img src={`data:image/png;base64, ${kycImageData}`} alt="..." />
          </div>
        </DialogLayout>

        <Stack sx={{ marginBottom: "1rem" }} direction="column" spacing={2}>
          <p className="section-label p-0 m-0">INVESTOR DETAILS</p>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Name
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.fullName !== null && investor?.fullName !== ""
                  ? investor?.fullName
                  : "---"}
              </p>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Investor Code
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.invCode !== null && investor?.invCode !== ""
                  ? investor?.invCode
                  : "---"}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Phone Number
              </p>
              <p className="fs-med-medium p-0 m-0">
                {(investor?.countryCode ? investor?.countryCode : "") +
                  (investor?.mobile ? investor?.mobile : "") ?? "---"}
              </p>
            </Box>

            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Email
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.email !== null && investor?.email !== ""
                  ? investor?.email
                  : "---"}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                DOB
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.dobDoi !== null && investor?.dobDoi !== ""
                  ? formatDateTime(investor?.dobDoi, false)
                  : "---"}
              </p>
            </Box>

            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Address
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.address !== null && investor?.address !== ""
                  ? investor?.address
                  : "---"}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
          <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                City
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.cityName !== null && investor?.cityName !== ""
                  ? investor?.cityName
                  : "---"}
              </p>
            </Box>

            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Country
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.countryName !== null && investor?.countryName !== ""
                  ? investor?.countryName
                  : "---"}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Investor Type
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.investorType !== null &&
                  investor?.investorType !== ""
                  ? investorTypeList.find(
                    (item) => item.ddIndex === investor?.investorType
                  )?.ddValue
                  : "---"}
              </p>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                STAMP PAPER?
              </p>
              <p className="fs-med-medium p-0 m-0">
                {(investor?.stampPaper ? "YES" : "NO") ?? "---"}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Upfront
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.upfront !== null && investor?.upfront !== ""
                  ? investor?.upfront
                  : "---"}
                %
              </p>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Class Name
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.className !== null && investor?.className !== ""
                  ? investor?.className
                  : "---"}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            {userType === "ADMIN" && (
              <Box sx={{ flex: 1 }}>
                <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                  Partner Name
                </p>
                <p className="fs-med-medium p-0 m-0">
                  {investor?.partnerName !== null &&
                    investor?.partnerName !== ""
                    ? investor?.partnerName
                    : "---"}
                </p>
              </Box>
            )}
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                USER CREATED DATE
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.createdDatetime !== null && investor?.createdDatetime !== ""
                  ? formatDateTime(investor?.createdDatetime, false)
                  : "---"}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                INCL DEMAT CHARGES?
              </p>
              <p className="fs-med-medium p-0 m-0">
                {(investor?.includeDematCharge ? "YES" : "NO") ?? "---"}
              </p>
            </Box>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          sx={{ marginBottom: "1rem", marginTop: "1rem" }}
          direction="column"
          spacing={2}
        >
          <p className="section-label p-0 m-0">BANK DETAILS</p>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Bank Name
              </p>
              <div className="fs-med-medium p-0 m-0 d-flex flex-row gap-2">
                <p>
                {investor?.bankIndex !== null && investor?.bankIndex !== ""
                  ? investor?.bankIndex === 0
                    ? investor?.otherBankName
                    : bankNameList.find((item) => item.ddIndex === investor?.bankIndex)?.ddValue
                  : "---"}
                </p>
                <p>
                {investor?.otherBankName && investor.bankIndex ===0 ? (
                <Tooltip title="Investor Selected Other Bank Name" placement="bottom">
                  <Box display="d-flex flex-row " alignItems="center">
                    <b>
                    <InfoOutlinedIcon sx={{ fontSize: '16px', color: 'red', fontWeight:'bold' }} />
                    <span className="text-danger px-1"> Need Action</span>
                    </b>
                  </Box>
                </Tooltip>
              ) : null}
                </p>
              </div>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Address
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.bankAddress !== null && investor?.bankAddress !== ""
                  ? investor?.bankAddress
                  : "---"}
              </p>

            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Account Number
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.accountNo !== null && investor?.accountNo !== ""
                  ? investor?.accountNo
                  : "---"}
              </p>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                IFSC
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.ifscSwift !== null && investor?.ifscSwift !== ""
                  ? investor?.ifscSwift
                  : "---"}
              </p>
            </Box>
          </Stack>
        </Stack>
        <Divider />

        <Stack
          sx={{ marginBottom: "1rem", marginTop: "1rem" }}
          direction="column"
          spacing={2}
        >
          <p className="section-label p-0 m-0">DEMAT DETAILS</p>

          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>Demat Account Number</p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.dematAccNo ?? '---'}
              </p>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>Depository Participant (DP) Name</p>
              <div className="fs-med-medium p-0 m-0 d-flex flex-row gap-2">
                <p>
                {investor?.dpDdIndex !== null && investor?.dpDdIndex !== ""
                  ? investor?.dpDdIndex === 0
                    ? investor?.otherDematName
                    : dpNameList.find((item) => item.ddIndex === investor?.dpDdIndex)?.ddValue
                  :investor?.dpName?investor?.dpName: "---"}
                </p>

                {/* <p className="fs-med-medium p-0 m-0">
                {dpNameList.find(
                  (item) => item.ddIndex === investor?.dpDdIndex
                )?.ddValue ?? '---'}
              </p> */}

                <p>
                {investor?.otherDematName && investor.dpDdIndex ===0 ? (
                <Tooltip title="Investor Selected Other Depository Participant (DP) Name" placement="bottom">
                  <Box display="d-flex flex-row " alignItems="center">
                    <b>
                    <InfoOutlinedIcon sx={{ fontSize: '16px', color: 'red', fontWeight:'bold' }} />
                    <span className="text-danger px-1"> Need Action</span>
                    </b>
                  </Box>
                </Tooltip>
              ) : null}
                </p>
              </div>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>DP ID</p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.dpId ?? '---'}
              </p>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>Client ID</p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.dpClientId ?? '---'}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>First Holder Name</p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.firstHolderName ?? '---'}
              </p>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>CML COPY</p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.cmlCopyObject ? <Tooltip title={investor?.cmlCopyObject?.actualFileName}>
                  <AttachFileIcon color="secondary" onClick={() => window.open(`${BASE_URL}/${investor?.cmlCopyObject?.path}`, '_blank')} />
                </Tooltip> : '---'}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>NSDL / CDSL</p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.nsdlCdsl ?? '---'}
              </p>
            </Box>
          </Stack>
        </Stack>

        <Divider />
        <Stack
          sx={{ marginBottom: "1rem", marginTop: "1rem" }}
          direction="column"
          spacing={2}
        >
          <p className="section-label p-0 m-0">CKYC</p>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Pan Number
              </p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.pan !== null && investor?.pan !== ""
                  ? investor?.pan
                  : "---"}
              </p>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Status
              </p>
              <p className="fs-med-medium p-0 m-0">
                {ckycStatusFromServer ? 'Verified' : 'Not Verified'}
                {
                  ckycStatusFromServer && kycDetails?.ckycavailable === 'Yes'
                    ? <Info color="primary" className="ms-1" style={{ cursor: 'pointer' }} onClick={() => setShowKycDetails(!showKycDetails)} />
                    : ''
                }
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>GST Number</p>
              <p className="fs-med-medium p-0 m-0">
                {investor?.gstNo ?? '---'}
              </p>
            </Box>
          </Stack>
          {
            showKycDetails ?
              <Stack direction="row" spacing={3} alignItems="center">
                <Box sx={{ flex: 1 }}>
                  <div className="d-flex gap-3 align-items-center">
                    <img src={`data:image/png;base64, ${kycDetails?.ckycphoto}`} alt="..." style={{ cursor: "pointer", width: '70px' }} onClick={() => toggleKycImage(kycDetails?.ckycphoto)} />
                    <div className="d-flex flex-column">
                      <p className="section-label mb-1" style={{ fontSize: "12px" }}>Name</p>
                      <p className="fs-med-medium p-0 m-0">{kycDetails?.ckycname ?? "---"}</p>
                    </div>
                  </div>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <p className="section-label mb-1" style={{ fontSize: "12px" }}>Account Type</p>
                  <p className="fs-med-medium p-0 m-0">{kycDetails?.ckycaccType ?? "---"}</p>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <p className="section-label mb-1" style={{ fontSize: "12px" }}>CKYC ID</p>
                  <p className="fs-med-medium p-0 m-0">{kycDetails?.ckycid ?? "---"}</p>
                </Box>
              </Stack> : ''
          }
        </Stack>
        {ckycStatus ? (
          <div>
            <Divider />
            <Stack
              sx={{ marginBottom: "1rem", marginTop: "1rem" }}
              direction="column"
              spacing={2}
            >
              <Stack direction="row" spacing={2}>
                <FormControl sx={{ flex: 1 }}>
                  <Box sx={{ flex: 1 }}>
                    <p
                      className="section-label mb-1"
                      style={{ fontSize: "12px" }}
                    >
                      Investment Preference
                    </p>
                    <p className="fs-med-medium p-0 m-0">
                      {investor?.invPref === 1
                        ? "Lumpsum Investment"
                        : investor?.invPref === 2
                          ? "SIP Investment"
                          : "None"}
                    </p>
                  </Box>
                </FormControl>
                <Box sx={{ flex: 1 }} />
              </Stack>
            </Stack>
          </div>
        ) : (
          ""
        )}
      </DrawerLayout>
    </LocalizationProvider>
  );
};
export default InvestorInfo;
