import React, { useState, useEffect, useMemo } from "react";
import DrawerLayout, { ScoreBoard } from "../../components/layout/DrawerLayout";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import {
  apiDetailedFeeGenerateReport,
  apiFeeGenerateReport,
  apiFunds,
  apiInvestorFeeGenerated,
  apiInvestorOpportunity,
  apiSchemeSummary,
  getOpportunity,
} from "../../utils/api/api";
import { SNACKBAR_ERROR } from "../../utils/constants/constants";
import { toLocalePrice } from "../../utils/StringUtil";
import { useSnackbar } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from '@mui/material/styles';
import { TableHeadButton } from "../../components/ui/TableHeadButton";


const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    border: '1px solid #000',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
  },
}));

const FeeGenerated = ({ open, toggleDrawer, fundTotalObj }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [upFront, setUpFront] = useState(0.0);
  const [gst, setGST] = useState(0.0);
  const [total, setTotal] = useState(0.0);
  const [investorList, setInvestorList] = useState([]);
  const [opportunityList, setOpportunityList] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [actionLoader, setActionLoader] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });


  const downloadFile = async (response) => {
    const blob = new Blob([response.data], {
      type: response.headers.get("content-type"),
    });
    const downloadLink = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.setAttribute(
      "download",
      response.headers.get("content-disposition").split('"')[1]
    );
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const getOpportunityList = () => {
    apiSchemeSummary()
      .then((data) => {
        setOpportunityList(data);
        console.log('daaata', data)

      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const getInvestorOpportunity = (param = {}) => {
    setIsLoading(true);

    param.oppRid = expanded;

    apiInvestorFeeGenerated(param)
      .then((data) => {
        setIsLoading(false);
        setInvestorList(data);
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const getFeeGeneratedFile = () => {
    setActionLoader(true);
    apiFeeGenerateReport({
      oppRid: null,
    })
      .then((data) => {
        setActionLoader(false);
        downloadFile(data);
        setAnchorEl(null);
      })
      .catch((error) => {
        setActionLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const getDetailedFeeGeneratedFile = () => {
    setActionLoader(true);
    apiDetailedFeeGenerateReport({
      fundStatus: null,
    })
      .then((data) => {
        setActionLoader(false);
        downloadFile(data);
        setAnchorEl(null);
      })
      .catch((error) => {
        setActionLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const sortedInvestmentList = useMemo(() => {
    if (sortConfig.key) {
      return [...investorList].sort((a, b) => {
        const aValue = a[sortConfig.key] || ""; // Replace null with an empty string so null values will be sorted at rop or bottom 
        const bValue = b[sortConfig.key] || ""; 
        
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return investorList;
  }, [investorList, sortConfig]);
  


  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
      direction = 'ascending';
    }
    setSortConfig({ key, direction });
  };


  useEffect(() => {
    expanded !== null && getInvestorOpportunity();
  }, [expanded]);

  useEffect(() => {
    if (open) {

      getOpportunityList();
    }
  }, [open]);

  return (
    <DrawerLayout
      open={open}
      title="Fee Generated"
      closeAction={toggleDrawer}
      positiveAction={{ label: "Close", onClick: toggleDrawer }}
    >
      <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
        <ScoreBoard
          label="Total Upfront Fee"
          value={toLocalePrice(fundTotalObj?.totalUpfrontFee)}
          flex={1}
        />
        <ScoreBoard
          label="Total Additional Charges"
          value={toLocalePrice(fundTotalObj?.totalAdditionalCharges)}
          flex={1}
        />
        <ScoreBoard
          label="Total Additional Income"
          value={toLocalePrice(fundTotalObj?.totalAdditionalIncome)}
          flex={1}
        />
        <ScoreBoard
          label="Total Scheme Expenses"
          value={toLocalePrice(fundTotalObj?.totalSchemeExpenses)}
          flex={1}
        />
        <ScoreBoard
          label="Partner Payments"
          value={toLocalePrice(fundTotalObj?.totalPartnerPayment)}
          flex={1}
        />
        <ScoreBoard
          label="Net Income"
          value={toLocalePrice(fundTotalObj?.totalNetIncome)}
          flex={1}
        />
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="column" spacing={2}>
        <div className="d-flex justify-content-between align-items-center">
          <p className="section-label p-0 m-0">Scheme Wise</p>
          <div>
            <IconButton
              color="secondary"
              id="basic-button"
              aria-controls={anchorEl ? "basic-menu" : undefined}
              aria-haspopup="true"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreVertIcon fontSize="medium" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              elevation={3}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem className="fs-large-regular" onClick={getFeeGeneratedFile}>
                Partner Payments - Scheme wise
              </MenuItem>
              <MenuItem className="fs-large-regular" onClick={getDetailedFeeGeneratedFile}>
                Partner Payments - Detailed
              </MenuItem>
            </Menu>
          </div>
        </div>
        <Stack>
          {opportunityList.map((data, key) => {
            return (
              <Accordion
                key={data.oppRid}
                expanded={expanded === data.oppRid}
                onChange={handleChange(data.oppRid)}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon color='primary' />}
                  aria-controls={`panel-${data.oppRid}-content`}
                  id={`panel-${data.oppRid}-header`}
                >
                  <div className="d-flex gap-3 align-items-center w-100">
                    <div className="d-flex flex-grow-1">
                      <h5 className="fs-med-semibold p-0 m-0">
                        {data.oppTitle}
                      </h5>
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      <CustomTooltip title={

                        <div className="d-flex gap-2 align-items-center w-100">
                          <table>
                            <tr >
                              <td ><p className="fs-normal-medium text-primary p-0 m-0">Partner Payments</p>
                              </td>
                              <td className="text-end ps-4"><p className="fs-normal-medium text-primary p-0 m-0">{toLocalePrice(data.partnerFee)}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="fs-normal-medium text-primary p-0 m-0">Scheme Expenses</p>
                              </td>
                              <td className="text-end ps-4">
                                <p className="fs-normal-medium text-primary p-0 m-0">{toLocalePrice(data.schemeExpenses)}</p>
                              </td>
                            </tr>

                          </table>

                        </div>
                      } placement="top" arrow>
                        <Chip
                          variant="outlined"
                          color="error"
                          label={
                            <div className="fs-normal-semibold">
                              <span className="fs-normal-regular">Expenses : </span>
                              {/* {toLocalePrice(data.expenses)}<br/> */}
                              {toLocalePrice(data.expense)}

                            </div>
                          }
                        />
                      </CustomTooltip>

                      <Chip
                        variant="outlined"
                        label={
                          <div className="fs-normal-semibold">
                            <span className="fs-normal-regular">Statutory Fees : </span>
                            {toLocalePrice(data.gst)}
                          </div>
                        }
                      />

                      <CustomTooltip title={
                        <div className="d-flex gap-2 align-items-center w-100">
                          <table>
                            <tr >
                              <td ><p className="fs-normal-medium text-primary p-0 m-0">Upfront</p>
                              </td>
                              <td className="text-end ps-4"><p className="fs-normal-medium text-primary p-0 m-0">{toLocalePrice(data.upfrontAmount)}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="fs-normal-medium text-primary p-0 m-0">Demat Charges</p>
                              </td>
                              <td className="text-end ps-4">
                                <p className="fs-normal-medium text-primary p-0 m-0">{toLocalePrice(data.dematCharges)}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="fs-normal-medium text-primary p-0 m-0">Additional Charges</p>
                              </td>
                              <td className="text-end ps-4">
                                <p className="fs-normal-medium text-primary p-0 m-0">{toLocalePrice(data.additionalCharges)}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="fs-normal-medium text-primary p-0 m-0">Additional Income</p>
                              </td>
                              <td className="text-end ps-4">
                                {/*todo */}
                                <p className="fs-normal-medium text-primary p-0 m-0">{toLocalePrice(data.additionalIncome)}</p>
                              </td>
                            </tr>
                          </table>
                        </div>
                      } placement="top" arrow>
                        <Chip
                          variant="outlined"
                          color="success"
                          label={
                            <div className="fs-normal-semibold">
                              <span className="fs-normal-regular">Income : </span>
                              {toLocalePrice(data.income)}
                            </div>
                          }
                        />
                      </CustomTooltip>

                      {/* <Chip
                        variant="outlined"
                        label={
                          <div className="fs-normal-semibold">
                            <span className="fs-normal-regular">PP : </span>
                            {toLocalePrice(data.partnerFee)}
                          </div>
                        }
                      /> */}
                      <Chip
                        variant="outlined"
                        color="primary"
                        label={
                          <div className="fs-normal-semibold">
                            <span className="fs-normal-regular">Net Income : </span>
                            {toLocalePrice(data.netIncome)}
                          </div>
                        }
                        style={{
                          backgroundColor: (data.netIncome) >= 0 ? '#d7f9d7' : '#fbdde2',
                        }}
                      />

                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {expanded === data.oppRid && investorList.length > 0 ? (
                        <table className="table table-striped table-borderless p-0 m-0 w-100">
                          <thead>
                            <tr className="fs-normal-semibold text-center">
                              <th scope="col">
                                <TableHeadButton
                                  label="Investor"
                                  activeUp={sortConfig.key === 'fullName' && sortConfig.direction === 'ascending'}
                                  activeDown={sortConfig.key === 'fullName' && sortConfig.direction === 'descending'}
                                  onClick={() => handleSort('fullName')}
                                />
                              </th>
                              <th scope="col" colspan="2">
                               <TableHeadButton
                                  label="Upfront"
                                  activeUp={sortConfig.key === 'upfront' && sortConfig.direction === 'ascending'}
                                  activeDown={sortConfig.key === 'upfront' && sortConfig.direction === 'descending'}
                                  onClick={() => handleSort('upfront')}
                                  leftAlign
                                />
                              </th>
                              <th scope="col">
                              <TableHeadButton
                                  label="Statutory Fees"
                                  activeUp={sortConfig.key === 'gst' && sortConfig.direction === 'ascending'}
                                  activeDown={sortConfig.key === 'gst' && sortConfig.direction === 'descending'}
                                  onClick={() => handleSort('gst')}
                                  leftAlign
                                />
                              </th>
                              <th scope="col">
                              <TableHeadButton
                                  label="Additional Charges"
                                  activeUp={sortConfig.key === 'additionalCharges' && sortConfig.direction === 'ascending'}
                                  activeDown={sortConfig.key === 'additionalCharges' && sortConfig.direction === 'descending'}
                                  onClick={() => handleSort('additionalCharges')}
                                  leftAlign
                                />
                              </th>
                              <th scope="col">
                              <TableHeadButton
                                  label="Partner Payment"
                                  activeUp={sortConfig.key === 'partnerFee' && sortConfig.direction === 'ascending'}
                                  activeDown={sortConfig.key === 'partnerFee' && sortConfig.direction === 'descending'}
                                  onClick={() => handleSort('partnerFee')}
                                  leftAlign
                                />
                              </th>
                              <th scope="col">
                              <TableHeadButton
                                  label="Net Income"
                                  activeUp={sortConfig.key === 'netIncome' && sortConfig.direction === 'ascending'}
                                  activeDown={sortConfig.key === 'netIncome' && sortConfig.direction === 'descending'}
                                  onClick={() => handleSort('netIncome')}
                                  leftAlign
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {sortedInvestmentList.map((data, key) => {
                              return (
                                <tr
                                  className="fs-normal-medium text-center"
                                  key={key}
                                >
                                  <td className="pt-2">
                                    {data.fullName}
                                    <span className="fs-small-regular text-muted ms-2">
                                      {data.invCode}
                                    </span>
                                  </td>
                                  <td className="pt-2 text-end">
                                    {toLocalePrice(data.upfrontAmount)}
                                  </td>
                                  <td className="pt-2 text-start">
                                    <span className="fs-small-regular text-muted">
                                      {toLocalePrice(data.upfront)}%
                                    </span>
                                  </td>
                                  <td className="pt-2 text-end">
                                    {toLocalePrice(data.gst)}
                                  </td>
                                  <td className="pt-2 text-end">
                                    {toLocalePrice(data.additionalCharges)}
                                  </td>
                                  <td className="pt-2 text-end">
                                    {toLocalePrice(data.partnerFee)}
                                  </td>
                                  <td className="pt-2 text-end">
                                    {toLocalePrice(data.netIncome)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <p className="fs-med-regular p-0 m-0">No Data</p>
                      )}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Stack>
      </Stack>
    </DrawerLayout>
  );
};

export default FeeGenerated;
